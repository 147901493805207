<script setup lang="ts">
    import Tenant from '~/app/auth/models/Tenant';

    interface PayloadInterface {
        tenant: Tenant;
        callback: () => void;
    }

    const form = useForm<{
        consent: boolean;
    }>({
        consent: false,
    });

    const disabled = computed(() => !form.get('consent'));
    const callback = ref<() => void>();
    useModal(
        'settings:tenants:subscription',
        (payload: PayloadInterface) => {
            callback.value = payload?.callback;
        },
        () => {
            form.reset();
        }
    );

    const submit = () => {
        if (form.get('consent')) {
            callback.value();
        }
        useEvent('settings:tenants:subscription:close');
    };
</script>

<template>
    <Modal
        ref="modal"
        name="settings:tenants:subscription"
    >
        <!-- <ModalLoader v-if="loading" /> -->
        <div>
            <div :class="$theme('modal.title.container')">
                <div class="space-y-2">
                    <h2 :class="$theme('modal.title.text')">
                        {{ $t('settings.tenants.subscription.title') }}
                    </h2>
                    <AlertBox>
                        <p>{{ $t('settings.tenants.subscription.subtext') }}</p>
                    </AlertBox>
                    <!-- <div
                    :class="$theme('modal.title.subtext')"
                    class="bg-orange-600 text-white rounded text-sm"
                >
                    <div>
                        <i class="text-2xl fa-regular fa-triangle-exclamation" />
                    </div>
                </div> -->
                </div>
            </div>
            <form @submit.prevent="submit">
                <div :class="$theme('modal.padding')">
                    <div class="flex w-full mb-12">
                        <FormCheckbox
                            :form="form"
                            :input-name="'consent'"
                        />
                    </div>

                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('settings:tenants:subscription:close')"
                        >
                            {{ $t('actions.cancel') }}
                        </LoadingButton>
                        <LoadingButton
                            :class-name="disabled ? $theme('button.style.disabled') : $theme('button.style.default')"
                            :class="disabled ? 'cursor-not-allowed ' : ''"
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            :disabled="disabled"
                            type="submit"
                        >
                            {{ $t('actions.save') }}
                        </LoadingButton>
                    </div>
                </div>
            </form>
        </div>
    </Modal>
</template>
