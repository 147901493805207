<template>
    <p>{{ t('inventory.product.import_form.content.info') }}</p>
    <div class="flex justify-between px-3 py-2 mb-4 text-xs font-semibold text-black border border-gray-200">
        <span>
            {{ $t('inventory.product.import_form.preview_table.reference') }}
        </span>
        <span>
            {{ $t('inventory.product.import_form.preview_table.title') }}
        </span>
        <span>
            {{ $t('inventory.product.import_form.preview_table.description') }}
        </span>
        <span>
            {{ $t('inventory.product.import_form.preview_table.defaultQuantity') }}
        </span>
        <span>
            {{ $t('inventory.product.import_form.preview_table.price') }}
        </span>
        <span>
            {{ $t('inventory.product.import_form.preview_table.purchase_price') }}
        </span>
        <span>
            {{ $t('inventory.product.import_form.preview_table.unity') }}
        </span>
        <span>
            {{ $t('inventory.product.import_form.preview_table.category') }}
        </span>
        <span>{{ $t('inventory.product.import_form.preview_table.vat_number') }}</span>
    </div>
    <div class="space-y-4 text-xs font-normal text-center">
        <p>{{ t('inventory.product.import_form.content.file.info') }}</p>
        <div class="text-lg text-primary-dark">
            <a :href="$t('inventory.product.import_form.content.file.example.download_url')">
                <i class="fa-regular fa-file-csv"></i>
                {{ t('inventory.product.import_form.content.file.example.download') }}</a
            >
        </div>
    </div>
    <div :class="$theme('modal.footer.container')">
        <div :class="$theme('modal.footer.button_container')">
            <LoadingButton
                :extra-class-name="$theme('modal.footer.button')"
                :class-name="$theme('button.style.cancel')"
                prevent-default
                @clicked="$emit('close')"
                >{{ t('actions.cancel') }}</LoadingButton
            >
            <LoadingButton
                :extra-class-name="$theme('modal.footer.button')"
                @click="$emit('continue')"
                >{{ t('actions.continue') }}</LoadingButton
            >
        </div>
    </div>
</template>
<script lang="ts" setup>
    // defineProps<{}>();
    defineEmits(["close", "continue"]);

    const { t } = useTranslation();
</script>
