<script setup lang="ts">
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import InvoiceEndpoint from '~~/app/invoicing/endpoints/InvoiceEndpoint';
    import { InvoiceFormNextNumber, InvoiceValidateCustomInternalNumberResponse } from '~~/app/invoicing/endpoints/types';
    import InvoiceNumberGenerator from '~/classes/InvoiceNumberGenerator';
    import Invoice from '~~/app/invoicing/models/Invoice';

    interface PayloadInterface {
        invoice: Invoice;
    }
    const { $mixpanel } = useNuxtApp();
    const endpoint = EndpointFactory.make(ModelType.INVOICES) as InvoiceEndpoint;
    const invoice = reactiveModel<Invoice>(null);
    const defaultNextNumber: Ref<InvoiceFormNextNumber | null> = ref(null);
    const nextNumber: Ref<InvoiceFormNextNumber | null> = ref(null);
    const customInternalNumberValidation: Ref<InvoiceValidateCustomInternalNumberResponse | null> = ref(null);
    const customInternalNumberInput: Ref<HTMLInputElement | null> = ref(null);
    const checkingValidation = ref(true);
    const loading = ref(true);
    const success = ref(false);
    const validationChecks = ref(0);
    const form = useForm<{
        customInternalNumber: number;
    }>(
        {
            customInternalNumber: 0,
        },
        () => {
            validateCustomInternalNumber();
        }
    );

    useModal(
        'invoicing:invoice:mark-as-sent',
        (payload: PayloadInterface) => {
            invoice.value = payload.invoice;
            retrieveNumber();
        },
        () => {
            form.reset();
            validationChecks.value = 0;
            success.value = false;
        }
    );

    const retrieveNumber = async () => {
        loading.value = true;
        const response = await endpoint.form(invoice.value?.documentType ?? 'invoice', invoice.value?.getId());
        nextNumber.value = response.nextNumber;
        defaultNextNumber.value = response.nextNumber;
        form.set('customInternalNumber', response.nextNumber.internal);
        loading.value = false;
    };

    const openCustomInternalNumberSubmenu = async (toggle: () => void) => {
        toggle();
        await nextTick();
        customInternalNumberInput.value?.focus();
        customInternalNumberInput.value?.select();
    };

    const updateCustomInternalNumber = (hide: () => void) => {
        form.set('customInternalNumber', customInternalNumberInput.value?.valueAsNumber ?? 1);

        if (nextNumber.value) {
            nextNumber.value.formatted = InvoiceNumberGenerator.make(nextNumber.value.format, nextNumber.value.padding, form.get('customInternalNumber'));
        }

        hide();
    };

    const validateCustomInternalNumber = async () => {
        checkingValidation.value = true;
        customInternalNumberValidation.value = null;
        const response = await endpoint.validateCustomInternalNumber(invoice.value?.getId(), form.get('customInternalNumber'));
        customInternalNumberValidation.value = response;
        validationChecks.value++;
        checkingValidation.value = false;
    };

    const submit = async () => {
        $mixpanel.track(`invoicing_invoices_${invoice.value?.documentType}mark_as_sent`);
        const response = await form.loadUntil(endpoint.markAsSent(invoice.value!.getId(), form.get('customInternalNumber')));

        if (response.data) {
            useEvent('invoicing:invoice:sent', response.data);
            success.value = true;
        }
    };
</script>

<template>
    <Modal
        ref="modal"
        name="invoicing:invoice:mark-as-sent"
    >
        <ModalLoader v-if="loading" />
        <template v-else>
            <template v-if="success">
                <ModalLottie
                    :title="$t('invoicing.invoice_mark_as_sent_modal.success.title')"
                    :subtitle="
                        $t('invoicing.invoice_mark_as_sent_modal.success.text', {
                            number: invoice.number,
                        })
                    "
                >
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="useEvent('invoicing:invoice:mark-as-sent:close')"
                            >{{ $t('actions.close') }}
                        </LoadingButton>
                    </div>
                </ModalLottie>
            </template>
            <template v-else>
                <div :class="$theme('modal.title.container')">
                    <h2 :class="$theme('modal.title.text')">
                        {{ $t('invoicing.invoice_mark_as_sent_modal.title') }}
                    </h2>
                    <h3 :class="$theme('modal.title.subtext')">
                        {{ $t('invoicing.invoice_mark_as_sent_modal.subtext') }}
                    </h3>
                </div>
                <form @submit.prevent="submit">
                    <div :class="$theme('modal.padding')">
                        <div class="flex justify-center w-full mb-12">
                            <Submenu
                                overlay
                                position="center-x"
                            >
                                <template #default="{ toggle }">
                                    <span
                                        class="inline-block px-6 py-2 text-4xl font-thin text-gray-600 transition-all rounded-lg cursor-pointer hover:bg-neutral-100 hover:text-primary"
                                        @click.prevent="openCustomInternalNumberSubmenu(toggle)"
                                        >{{ nextNumber?.formatted }}</span
                                    >
                                </template>
                                <template #submenu="{ hide }">
                                    <div class="w-[200px] flex flex-col space-y-6">
                                        <p class="text-center">
                                            {{ $t('invoicing.invoice_mark_as_sent_modal.set_new_number') }}
                                        </p>
                                        <input
                                            ref="customInternalNumberInput"
                                            type="number"
                                            :value="form.get('customInternalNumber')"
                                            class="block w-full px-4 py-2 text-2xl text-center text-gray-600 focus:text-black input-number-without-arrows"
                                        />
                                        <LoadingButton
                                            prevent-default
                                            @clicked="updateCustomInternalNumber(hide)"
                                        >
                                            <i class="mr-2 fa-regular fa-circle-check" />
                                            {{ $t('actions.confirm') }}
                                        </LoadingButton>
                                    </div>
                                </template>
                            </Submenu>
                        </div>

                        <InvoicingInvoiceMarkAsSentLegalReminder
                            v-if="invoice?.documentType == 'invoice' && nextNumber"
                            :invoice="invoice"
                            :default-next-number="nextNumber.internal"
                            :wanted-next-number="form.get('customInternalNumber')"
                        />

                        <InvoicingInvoiceMarkAsSentCustomInternalNumberValidation
                            v-if="customInternalNumberValidation && validationChecks > 1"
                            :custom-internal-number-validation="customInternalNumberValidation"
                            :invoice="invoice"
                        />

                        <div :class="$theme('modal.footer.button_container')">
                            <LoadingButton
                                :extra-class-name="$theme('modal.footer.button')"
                                :class-name="$theme('button.style.cancel')"
                                prevent-default
                                @clicked="useEvent('invoicing:invoice:mark-as-sent:close')"
                            >
                                {{ $t('actions.cancel') }}
                            </LoadingButton>
                            <LoadingButton
                                :class-name="checkingValidation ? $theme('button.style.disabled') : $theme('button.style.default')"
                                :extra-class-name="$theme('modal.footer.button')"
                                :disabled="checkingValidation"
                                :loading="form.isLoading"
                                type="submit"
                            >
                                {{ $t('actions.mark_as_sent') }}
                            </LoadingButton>
                        </div>
                    </div>
                </form>
            </template>
        </template>
    </Modal>
</template>
