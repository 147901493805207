import ComposedWorkLineCollection from '../collections/ComposedWorkLineCollection';
import ProductCategory from './ProductCategory';
import ProductUnit from './ProductUnit';
import ProductImage from './ProductImage';
import BaseModel from '~~/app/base/models/BaseModel';
import { ModelType } from '~~/app/base/schemas/BaseSchema';
import VatRate from '~~/app/invoicing/models/VatRate';

export default class Product extends BaseModel {
    type: ModelType = ModelType.PRODUCTS;
    static type: ModelType = ModelType.PRODUCTS;
    prefix = 'inventory:product';
    static prefix = 'inventory:product';

    get productCategory(): ProductCategory | null {
        return this.relationships?.productCategory?.data;
    }

    get composedWorkLines(): ComposedWorkLineCollection | null {
        return this.relationships?.composedWorkLines?.data;
    }

    get productType(): string | null {
        return this.attributes?.productType;
    }

    get productUnit(): ProductUnit | null {
        return this.relationships?.productUnit?.data;
    }

    get productImage(): ProductImage | null {
        return this.relationships?.productImage?.data;
    }

    get defaultVatRate(): VatRate | null {
        return this.relationships?.defaultVatRate?.data;
    }

    get title(): string {
        return this.attributes.title;
    }

    get description(): string {
        return this.attributes.description;
    }

    get reference(): string {
        return this.attributes.reference;
    }

    get defaultQuantity(): number {
        return this.attributes.defaultQuantity;
    }

    set defaultQuantity(quantity: number) {
        this.attributes.defaultQuantity = quantity;
    }

    get price(): number {
        return this.attributes.price;
    }

    get purchasePrice(): number {
        return this.attributes.purchasePrice;
    }

    get margin(): number {
        return this.attributes.margin;
    }

    get subtotal(): number {
        return this.attributes.price * this.attributes.defaultQuantity;
    }

    get totalTax(): number {
        return (this.subtotal / 100) * (this.defaultVatRate?.rate ?? 0);
    }

    get total(): number {
        return this.subtotal + this.totalTax;
    }

    get count(): number {
        return this.meta?.count;
    }

    canBeSavedInLibrary(): boolean {
        const { authed } = useAuthStore();

        if (!authed?.hasPermission('inventory:product:create') || !authed?.hasPermission('inventory:product:update')) {
            return false;
        }
        return true;
    }
}
