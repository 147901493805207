<script setup lang="ts">
    const form = useForm<{}>({});

    useModal('worksite:worksite-progress-billing:quote-edit-disable-info', () => {});

    const understood = (understood: boolean) => {
        useEvent('worksite:worksite-progress-billing:quote-edit-disable-info:close', { understood });
    };
</script>

<template>
    <Modal
        ref="modal"
        name="worksite:worksite-progress-billing:quote-edit-disable-info"
    >
        <div :class="$theme('modal.title.container')">
            <h2 :class="$theme('modal.title.text', 'px-12')">
                {{ $t('worksite.progress_billing_select_quote_edit_disable.title') }}
            </h2>
        </div>
        <div :class="$theme('modal.padding')">
            <div class="flex justify-center mb-8">
                <div class="max-w-[180px] h-[180px]">
                    <Lottie json="https://shared-assets.trustup.io/lottie/warning.json" />
                </div>
            </div>

            <div :class="$theme('modal.footer.button_container')">
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :class-name="$theme('button.style.cancel')"
                    prevent-default
                    @clicked="useEvent('worksite:worksite-progress-billing:quote-edit-disable-info:close')"
                >
                    {{ $t('actions.cancel') }}
                </LoadingButton>
                <LoadingButton
                    :extra-class-name="$theme('modal.footer.button')"
                    :class-name="$theme('button.style.default')"
                    :loading="form.isLoading"
                    @clicked="understood(true)"
                >
                    {{ $t('actions.understood') }}
                </LoadingButton>
            </div>
        </div>
    </Modal>
</template>
