<script setup lang="ts">
interface Props {
        contactType: 'client' | 'supplier';
        multiple?: boolean;
        initialValue?: _VTI_TYPE_Contact | string | null;
        closeOnSelect?: boolean;
        submenuPosition?: 'left-0' | 'right-0';
        withCreateButton?: boolean;
        disabled?: boolean;
    }
    import { useMeilisearch } from '../../../composables/useMeilisearch'
    import { useEvent } from '@/composables/useEventBus'
    import { ModelType } from '~~/app/base/schemas/BaseSchema'
    import EndpointFactory from '~~/app/factories/EndpointFactory'
    import ContactCollection from '~~/app/contact/collections/ContactCollection'
    import ContactEndpoint from '~~/app/contact/endpoints/WorskiteEndpoint'
    
    const emit = defineEmits(['selected']);
    
    const props = defineProps({
  contactType: null,
  multiple: { type: Boolean, default: false },
  initialValue: { default: null },
  closeOnSelect: { type: Boolean, default: true },
  submenuPosition: { default: 'left-0' },
  withCreateButton: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false }
});
    const loading = ref(true);
    const submenu = ref(null);
    const contacts: Ref<ContactCollection | null> = ref(null);
    const selected: Ref<Contact | null> = ref(props.initialValue);
    if (!selected.value) {
        selected.value = null;
    }
    const query: Ref<string> = ref('');
    const isSelected = (contact: any) => {
        return selected.value && selected.value.getId() == contact.uuid;
    };
    const select = async (contactUuid: string | null) => {
        if (!contactUuid) return;
        await getContact(contactUuid);
        emit('selected', selected.value);
        if (props.closeOnSelect) {
            submenu.value?.hide();
        }
    };
    const show = () => {
        if (!submenu.value.isDisplayed) {
            submenu.value.show();
        }
    };
    const setValue = (contact: Contact) => {
        selected.value = contact;
    };
    const { search } = useMeilisearch();
    const meilisearchContacts = ref();
    const searchContacts = async (e: Event) => {
        loading.value = true;
        const results = await search({
            model: 'contacts',
            query: query.value,
            filter: `contact_type = ${props.contactType}`,
        });
        // should define type
        meilisearchContacts.value = results.hits;
        loading.value = false;
    };
    const displayName = (contact: any) => {
        return contact.company_name ? contact.company_name : `${contact.first_name ?? ''} ${contact.last_name ?? ''}`;
    };
    const getContact = async (contactUuid: string) => {
        loading.value = true;
        const endpoint = EndpointFactory.make(ModelType.CONTACTS) as ContactEndpoint;
        const response = await endpoint.setInclude('defaultVatRate').retrieve(contactUuid);
        if (response.error) {
            useToasteoError();
            submenu.value?.hide();
            return;
        }
        selected.value = response.data;
        loading.value = false;
    };
    useListen('contact:contact:created', (contact) => {
        if (props.withCreateButton) {
            select(contact.getId());
        }
    });
    defineExpose({
        show,
        setValue,
    });
    onMounted(async () => {
        if (props.initialValue && typeof props.initialValue !== 'string' && typeof props.initialValue?.isModelOrCollection === 'function') {
            selected.value = props.initialValue;
            return;
        }
        if (typeof props.initialValue === 'string' && props.initialValue !== '') {
            console.log('In a contact select with an initialValue of string', props.initialValue);
            const { load, model } = useModelLoader(ModelType.CONTACTS, props.initialValue);
            await load();
            if (model.value) {
                selected.value = model.value;
            }
        }
    });
</script>

<template>
    <Submenu
        v-if="!disabled"
        ref="submenu"
        :position="submenuPosition"
        :container="$theme('submenu.container.xl')"
        max-height="max-h-[400px]"
        @shown="searchContacts"
    >
        <template #default="slotProps">
            <div
                class="flex items-stretch justify-between px-3 py-2 transition-all border border-gray-200 rounded cursor-pointer hover:bg-neutral-100 active:bg-neutral-200 hover:text-primary group"
                @click.prevent="slotProps.toggle()"
            >
                <div class="flex items-center space-x-2">
                    <div>
                        <i
                            class="transition-all group-hover:text-primary fa-regular fa-user-tie"
                            :class="selected ? 'text-neutral-600' : 'text-neutral-400'"
                        ></i>
                    </div>
                    <div>
                        <span
                            class="text-sm transition-all text-neutral-500 group-hover:text-primary"
                            v-text="selected ? selected.displayName : $t('selects.contact.placeholder')"
                        ></span>
                    </div>
                </div>
                <div>
                    <template v-if="withCreateButton">
                        <a
                            href="#"
                            :class="$theme('button.style.gray_sm', 'hover:bg-neutral-300')"
                            @click.stop.prevent="
                                useEvent('contact:contact:form-modal:open', {
                                    fields: { contactType },
                                })
                            "
                        >
                            <span class="hidden md:inline">
                                {{ $t('actions.add') }}
                            </span>
                            <span class="inline md:hidden">
                                <i class="fa-regular fa-plus" />
                            </span>
                        </a>
                    </template>
                    <slot name="button"></slot>
                </div>
            </div>
        </template>

        <template #submenu>
            <div class="w-full min-w-[260px]">
                <p class="mb-2">
                    {{ $t('selects.contact.title') }}
                </p>
                <div class="relative mb-2">
                    <input
                        v-model="query"
                        type="search"
                        autocomplete="off"
                        class="w-full py-2 pl-4 pr-8 text-sm rounded-lg bg-neutral-100"
                        :placeholder="$t('contact.contacts.search_input_placeholder')"
                        @input.prevent.stop="searchContacts($event)"
                    />
                    <div class="absolute top-0 bottom-0 right-0 flex items-center pr-2">
                        <i
                            class="text-sm transition-all cursor-pointer text-neutral-600 fa-regular fa-search hover:text-black"
                            @click.prevent.stop="searchContacts($event)"
                        ></i>
                    </div>
                </div>
                <div class="relative">
                    <div
                        v-if="loading"
                        class="py-6"
                    >
                        <Loader></Loader>
                    </div>
                    <template v-else>
                        <SubmenuEmptyContent v-if="!meilisearchContacts.length" />
                        <div
                            v-else
                            class="flex flex-col"
                        >
                            <!-- // when select should trigger show  as now we display results from meilisearch index-->
                            <div
                                v-for="meilisearchContact in meilisearchContacts"
                                :key="meilisearchContact.uuid"
                                :class="[$theme('contextmenu.link', 'cursor-pointer'), { 'bg-neutral-100': isSelected(meilisearchContact) }]"
                                @click.stop.prevent="select(meilisearchContact.uuid)"
                                @contextmenu.prevent="
                                    ($event) =>
                                        useMeilisearchHitContextMenu({
                                            ModelType: ModelType.CONTACTS,
                                            uuid: meilisearchContact.uuid,
                                            event: $event,
                                        })
                                "
                            >
                                <div>
                                    <span class="text-sm">{{ `${displayName(meilisearchContact)}` }}</span>
                                </div>
                                <div
                                    v-if="isSelected(meilisearchContact)"
                                    class="absolute top-0 bottom-0 right-0 flex items-center pr-3"
                                >
                                    <a
                                        href="#"
                                        @click.prevent.stop="select(null)"
                                    >
                                        <i class="text-neutral-400 fa-regular fa-xmark hover:text-neutral-600"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </Submenu>
</template>
