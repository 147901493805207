<script setup lang="ts">
    import { v4 as uuidV4 } from 'uuid';
    import { ProductsIndexSchema } from '../../../app/common/schemas/ProductsIndexSchema';
    import ProductSectionEndpoint from '../../../app/inventory/endpoints/ProductSectionEndpoint';
    import ProductSectionSchema from '~~/app/inventory/schemas/ProductSectionSchema';
    import ProductSection from '@/app/inventory/models/ProductSection';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import Product from '~~/app/inventory/models/Product';
    import useDragableProducts from '~/composables/useDragableProducts';

    const show = ref(false);

    export interface ProductSearchFields {
        title: string;
        defaultQuantity: number;
        products: Product[];
        // section?: ProductSection;
    }
    const form = useForm<ProductSearchFields>({
        title: '',
        defaultQuantity: 0,
        products: [],
        // section: undefined,
    });

    const selectedProducts = ref<ProductsIndexSchema[]>([]);
    const { onDragLeave, onDragOver, onDrop, startDrag, placeholderIndex } = useDragableProducts(selectedProducts);
    const showAndAddProductSelect = () => {
        selectedProducts.value.push({ uuid: uuidV4() as string });
        show.value = true;
    };

    const selectProduct = (value: ProductsIndexSchema, product: ProductsIndexSchema) => {
        const indexOfSelected = selectedProducts.value?.findIndex((selectedProduct: { uuid: string }) => product.uuid === selectedProduct.uuid);
        if (indexOfSelected === -1) return console.error('No match found');
        selectedProducts.value.splice(indexOfSelected, 1, value);
    };

    const removeProduct = (product: ProductsIndexSchema) => {
        const indexOfSelected = selectedProducts.value?.findIndex((selectedProduct: { uuid: string }) => product.uuid === selectedProduct.uuid);
        if (indexOfSelected === -1) return console.error('No match found');
        selectedProducts.value.splice(indexOfSelected, 1);
    };

    const { modalName, model } = useModelFormModal<ProductSection, ProductSearchFields>(
        ProductSection,
        (payload?: FormModalPayload<ProductSection, ProductSearchFields>) => {
            onOpened(payload);
        },
        (payload) => {
            form.reset();
            if (!payload?.model) {
                show.value = false;
            }
        }
    );

    const fetchSectionWithProducts = async (productSection: ProductSection) => {
        const endpoint = EndpointFactory.make(ModelType.PRODUCT_SECTIONS);
        const response = await endpoint.setInclude('products,products.defaultVatRate,products.productCategory,products.productUnit,products.productImage').retrieve(productSection.getId());
        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }
        return response.data;
    };
    const { search } = useMeilisearch();
    const loading = ref<boolean>(false);
    const onOpened = async (payload?: FormModalPayload<ProductSection, ProductSearchFields>) => {
        loading.value = true;
        selectedProducts.value = [];

        if (payload?.model) {
            const section = await fetchSectionWithProducts(payload.model);
            form.fillWithModel(section);
            // TEST
            show.value = true;
            loading.value = false;

            if (!section?.products?.items.length) return;
            const promises = section?.products.items.map(async (item) => {
                const result = await search({
                    model: 'products',
                    query: item.title,
                });

                return result.hits[0];
            });

            // Wait for all promises to resolve and get the results in order
            const results = await Promise.all(promises);
            selectedProducts.value = results;
            form.set('products', selectedProducts.value);
        }
        loading.value = false;
    };

    // SUBMIT ////
    const submit = async () => {
        form.isLoading = true;
        // if (selectedProducts.value.length) form.set('products', await fetchSelectedProducts());
        if (!selectedProducts.value.length) return;
        const schema = ProductSectionSchema.make({
            attributes: form.dataExcept(['products']),
        });
        selectedProducts.value.forEach((product: ProductsIndexSchema) => {
            schema.addToManyRelationship('products', ModelType.PRODUCTS, product.uuid);
        });

        if (model.value) {
            schema.id = model.value?.getId();
        }

        const endpoint: ProductSectionEndpoint = EndpointFactory.make(ModelType.PRODUCT_SECTIONS) as ProductSectionEndpoint;
        const response = await form.loadUntil(endpoint.storeOrUpdate(schema));
        useEvent(`${modalName}:updated`, response.data);

        if (response.error) {
            if (!response.validationErrors) {
                useToasteoError();
            }
            return;
        }

        useToasteoSuccess();
        close();
    };

    const close = () => {
        form.reset();
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="ProductSection"
        scrollable
        @before-close="form.reset()"
    >
        <ModalLoader v-if="loading"></ModalLoader>
        <div v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ model ? $t('inventory.product_section.edit_form.title') : $t('inventory.product_section.create_form.title') }}
                </h2>
            </div>
            <form
                class="space-y-4"
                @submit.prevent="submit"
            >
                <div :class="$theme('modal.padding')">
                    <FormInput
                        :form="form"
                        input-name="title"
                        autofocus
                    />
                    <FormInput
                        :form="form"
                        input-name="defaultQuantity"
                        input-type="number"
                    />
                    <!-- // DRAG DROP -->
                    <div v-if="show">
                        <div class="productsContainer">
                            <div
                                v-for="(product, index) in selectedProducts"
                                :id="`item-${product.uuid}`"
                                :key="product.uuid"
                                class="transition-all relative item"
                                :class="{ placeholder: index === placeholderIndex }"
                                draggable="true"
                                @dragstart="startDrag($event, product, index)"
                                @dragover="onDragOver($event, index)"
                                @dragleave="onDragLeave($event, index)"
                                @drop="onDrop($event, index)"
                            >
                                <InventoryProductSectionProductSearchInput
                                    :item="index"
                                    :product="product"
                                    :form="form"
                                    class="item"
                                    @select-product="(value) => selectProduct(value, product)"
                                    @remove-product="() => removeProduct(product)"
                                />
                            </div>
                        </div>
                    </div>
                    <LoadingButton
                        :class-name="$theme('button.style.default_sm2')"
                        @clicked="showAndAddProductSelect()"
                        >{{ $t('inventory.product_section.add.products') }}
                    </LoadingButton>
                </div>

                <div :class="$theme('modal.footer.container')">
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close()"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.save') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
        </div>
    </ModelFormModal>
</template>

<style scoped>
    .productsContainer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .item {
        transition:
            transform 0.3s ease,
            background 0.3s ease;
        position: relative;
        z-index: 1;
        /* Ensure the element stays above others while dragging */
        background: white;
        /* Match the background color of your component */
    }

    .item.placeholder {
        opacity: 0.5;
        background: #e0e0e0;
        border-style: dashed;
    }
</style>
