<script setup lang="ts">
    import { storeToRefs } from 'pinia';

    const { closeLatestModal, getClickableOverlayZIndex } = useModalsStore();
    const { modals, displayOverlay } = storeToRefs(useModalsStore());
</script>

<template>
    <div
        :tabindex="displayOverlay ? 99 : -1"
        @keydown.esc="closeLatestModal"
    >
        <transition
            enter-active-class="transition-all duration-200 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-all duration-200 ease-out delay-150"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="displayOverlay"
                :style="`z-index: ${getClickableOverlayZIndex()};`"
                class="absolute inset-0 bg-[#0a183ab8] backdrop-blur-[1px]"
                @click="closeLatestModal"
            ></div>
        </transition>
        <transition
            enter-active-class="transition-all duration-200 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition-all duration-200 ease-out delay-150"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-show="displayOverlay"
                :class="[$theme('modal.overlay'), 'backdrop-blur-[2px] z-[890] bg-[#0a183a45]']"
            ></div>
        </transition>

        <AppSearchModal />
        <AppCouponsModal />

        <AuthLoginQRCodeModal />
        <AuthUserModal />
        <AuthTenantLiveModeModal />

        <WorksiteWorksiteUploadDropZone />

        <ContactContactContextMenu />
        <ContactContactModal />
        <ContactContactFormModal />
        <ContactContactImportModal />
        <ContactContactFormModalsDestroyUserAlert />

        <CrmLeadContextMenu />
        <CrmLeadFormModal />
        <!-- // TODO IT IS A CRM LEAD MODAL OR INVOICE ?  -->
        <CrmLeadQuoteModal />
        <CrmSourceFormModal />

        <TaskTaskContextMenu />
        <TaskTaskModal />
        <TaskTaskFormModal />

        <InventoryProductContextMenu />
        <InventoryProductFormModal />
        <InventoryProductImportModal />
        <InventoryProductUnitContextMenu />
        <InventoryProductUnitFormModal />
        <InventoryProductCategoryContextMenu />
        <InventoryProductCategoryFormModal />
        <InventoryProductSectionContextMenu />
        <InventoryProductSectionFormModal />

        <InvoicingExpenseContextMenu />
        <InvoicingExpensesExportModal />
        <InvoicingInvoiceModal />
        <InvoicingInvoiceContextMenu />
        <InvoicingInvoiceDeleteAlert />
        <InvoicingInvoicePreviewHtml />
        <InvoicingInvoiceLinkToWorksite />
        <InvoicingInvoiceChangeStatus />
        <InvoicingInvoiceMarkAsSent />
        <InvoicingInvoiceDueDate />
        <InvoicingInvoiceSendEmail />
        <InvoicingInvoiceSendSMS />
        <InvoicingInvoiceSendPaymentReminder />
        <InvoicingInvoiceReplicate />
        <InvoicingInvoiceDepositInvoice />
        <InvoicingInvoiceFinalInvoice />
        <InvoicingInvoiceFrenchVatCertificate />
        <!-- <InvoicingInvoicePayment /> -->
        <InvoicingInvoicePaymentFormModal />
        <InvoicingInvoicePaymentContextMenu />
        <InvoicingInvoiceDiscountFormModal />
        <InvoicingInvoiceDiscountContextMenu />
        <InvoicingVatRateContextMenu />
        <InvoicingVatRateFormModal />
        <InvoicingInvoicingPaymentRemindersSettingContextMenu />
        <InvoicingInvoicingPaymentRemindersSettingFormModal />
        <InvoicingInvoiceTemplateContextMenu />
        <InvoicingInvoiceTemplateFormModal />
        <InvoicingInvoiceTemplateSectionContextMenu />
        <InvoicingInvoiceTemplateSectionFormModal />
        <InvoicingInvoiceTemplateSectionColumnContextMenu />
        <InvoicingInvoiceTemplateSectionColumnFormModal />
        <InvoicingInvoiceTemplateReplicate />
        <InvoicingSettingsInvoiceAnnexPdfModal />
        <InvoicingSettingsInvoiceAnnexPdfContextMenu />
        <InvoicingInvoiceFormModalsWasteData />
        <InvoicingComposedWorkUpsellModal />
        <InvoicingInvoicesExportModal />
        <InvoicingInvoiceSelectContact />
        <InvoicingInvoiceFormModalsEditDisabled />

        <PlanningTimeActivityContextMenu />
        <PlanningTimeActivityModal />
        <PlanningTimeActivityFormModal />
        <PlanningCalendarEventContextMenu />
        <PlanningCalendarEventModal />
        <PlanningCalendarEventFormModal />
        <PlanningTimeActivityLinkInvoice />
        <PlanningTimeActivityInvoiceMultiple />

        <WorksiteWorksiteContextMenu />
        <WorksiteWorksiteModal />
        <WorksiteWorksiteFormModal />

        <CommonWasteCenterFormModal />
        <CommonModelFileContextMenu />
        <CommonModelFileModal />
        <CommonModelFileMoveModal />
        <CommonModelFileFolderContextMenu />
        <CommonModelFileFolderFormModal />
        <CommonMeilisearchHitContextMenu />
        <CommonFilesExportModal />

        <WorksiteWorksitePhaseFormModal />
        <WorksiteWorksitePhaseContextMenu />
        <WorksiteWorksiteProfitabilityDocumentContextMenu />
        <WorksiteWorksiteProfitabilityDocumentModal />
        <WorksiteWorksiteProfitabilityDocumentFormModal />
        <WorksiteWorksiteProgressBillingContextMenu />
        <WorksiteWorksiteProgressBillingSettingModal />
        <WorksiteWorksiteProgressBillingValidationModal />
        <WorksiteWorksiteProgressBillingInvoiceModal />
        <WorksiteWorksiteProgressBillingForceSavingProgressBillingModal />
        <WorksiteWorksiteClosureReportsFormModal />
        <WorksiteWorksiteClosureReportsContextMenu />
        <WorksiteWorksiteClosureReportsSendEmail />
        <WorksiteWorksiteProgressBillingModalsQuoteEditDisableInfo />
        <CommonNotificationsLogsModal />
        <CommonKonamiCodeModal />
        <CommonTermsOfServiceAcceptModal />

        <BankaccountAssignInvoiceToTransactionModal />

        <SettingsSpotsModal />
        <SettingsSubscriptionInfo />

        <InvoicingInvoicePaymentReminderGroupsFormModal />
        <InvoicingInvoicePaymentReminderGroupsContextMenu />
        <TeleportTarget id="modals-teleport-safe-target" />
        <div id="modals-teleport-target"></div>
    </div>
</template>
