<script setup lang="ts">
    import { v4 as uuidV4 } from 'uuid';
    import Product from '@/app/inventory/models/Product';
    import EndpointFactory from '~~/app/factories/EndpointFactory';
    import ProductSchema from '~~/app/inventory/schemas/ProductSchema';
    import { useEvent } from '@/composables/useEventBus';
    import { ModelType } from '~~/app/base/schemas/BaseSchema';
    import ProductUnit from '~~/app/inventory/models/ProductUnit';
    import ProductCategory from '~~/app/inventory/models/ProductCategory';
    import VatRate from '~~/app/invoicing/models/VatRate';
    import ProductCategoryCollection from '~~/app/inventory/collections/ProductCategoryCollection';
    import ProductUnitCollection from '~~/app/inventory/collections/ProductUnitCollection';
    import VatRateCollection from '~~/app/invoicing/collections/VatRateCollection';
    import ProductCategoryEndpoint from '~~/app/inventory/endpoints/ProductCategoryEndpoint';
    import ProductUnitEndpoint from '~~/app/inventory/endpoints/ProductUnitEndpoint';
    import VatRateEndpoint from '~~/app/invoicing/endpoints/VatRateEndpoint';
    import { ComposedWorkLinesIndexSchema } from '~/app/common/schemas/ProductsIndexSchema';
    import ComposedWorkLine from '~/app/inventory/models/ComposedWorkLine';
    import ComposedWorkLineSchema from '~/app/inventory/schemas/ComposedWorkLineSchema';
    import { ModelEndpointResponse } from '~/app/base/endpoints/types';
    import useDragableProducts from '~/composables/useDragableProducts';

    const loading = ref(true);
    const productCategories = reactiveCollection<ProductCategory, ProductCategoryCollection>(null);
    const productUnits = reactiveCollection<ProductUnit, ProductUnitCollection>(null);
    const vatRates = reactiveCollection<VatRate, VatRateCollection>(null);
    const show = ref(false);
    const shouldHide = ref(false);
    export interface ProductComposedFields {
        reference: string;
        title: string;
        description: string;
        purchasePrice: number;
        price: number;
        margin: number;
        defaultQuantity: number;
        productUnitId: number | null;
        productCategoryId: number | null;
        defaultVatRateId: number | null;
        productImageId: number | null;
        isComposedWork: boolean;
        composedWorkLines: ComposedWorkLine[];
    }

    const { search } = useMeilisearch();

    const form = useForm<ProductComposedFields>(
        {
            reference: '',
            title: '',
            description: '',
            purchasePrice: 0,
            price: 0,
            margin: 0,
            defaultQuantity: 0,
            productUnitId: null,
            productCategoryId: null,
            defaultVatRateId: null,
            productImageId: null,
            isComposedWork: false,
            composedWorkLines: [],
        },
        (field: string, value: any) => {
            if (field === 'purchasePrice' || field === 'price') {
                if (form.get('purchasePrice') == 0) {
                    form.setSilently('margin', 0);
                    return;
                }
                const increase = form.get('price') - form.get('purchasePrice');
                const margin = (increase / form.get('purchasePrice')) * 100;
                form.setSilently('margin', Math.round((margin + Number.EPSILON) * 100) / 100);
            }
        }
    );

    const selectedComposedWorkLines = ref<ComposedWorkLinesIndexSchema[]>([]);
    const { onDragLeave, onDragOver, onDrop, startDrag, placeholderIndex } = useDragableProducts(selectedComposedWorkLines);

    /** adding an input to allow the user to select a composed work line
     * we initlized it with an uuid for the index
     * property show pass to true to display it
     */
    const showAndAddComposedWorkLinetSelect = () => {
        selectedComposedWorkLines.value.push({ uuid: uuidV4() as string });
        show.value = true;
    };

    /** adding an index in selectedComposedWorkLines list */
    const selectComposedWorkLine = (value: ComposedWorkLinesIndexSchema, product: ComposedWorkLinesIndexSchema) => {
        const indexOfSelected = selectedComposedWorkLines.value?.findIndex((selectedProduct: { uuid: string }) => product.uuid === selectedProduct.uuid);
        if (indexOfSelected === -1) return console.error('No match found');
        selectedComposedWorkLines.value.splice(indexOfSelected, 1, value);
    };

    /** remove an index from selectedComposedWorkLines list */
    const removeComposedWorkLine = (product: ComposedWorkLinesIndexSchema) => {
        const indexOfSelected = selectedComposedWorkLines.value?.findIndex((selectedProduct: { uuid: string }) => product.uuid === selectedProduct.uuid);
        if (indexOfSelected === -1) return console.error('No match found');
        selectedComposedWorkLines.value.splice(indexOfSelected, 1);
        // removeRelationship(product);
    };

    const { modalName, model } = useModelFormModal<Product, Fields>(
        Product,
        (payload?: FormModalPayload<Product, Fields>) => onOpened(payload),
        () => {
            form.reset();
            shouldHide.value = false;
            selectedComposedWorkLines.value = [];
        }
    );
    const onOpened = async (payload?: FormModalPayload<Product, Fields>) => {
        loading.value = true;

        const endpoint1 = EndpointFactory.make(ModelType.PRODUCT_CATEGORIES) as ProductCategoryEndpoint;
        const endpoint2 = EndpointFactory.make(ModelType.PRODUCT_UNITS) as ProductUnitEndpoint;
        const endpoint3 = EndpointFactory.make(ModelType.VAT_RATES) as VatRateEndpoint;

        const responses = await Promise.all([endpoint1.index(), endpoint2.index(), endpoint3.index()]);

        productCategories.value = responses[0].data;
        form.set('productCategoryId', productCategories.value?.first()?.getId() ?? null);
        productUnits.value = responses[1].data;
        form.set('productUnitId', productUnits.value?.first()?.getId() ?? null);
        vatRates.value = responses[2].data;
        form.set('defaultVatRateId', vatRates.value?.first()?.getId() ?? null);

        if (payload?.model) {
            shouldHide.value = true;
            form.fillWithModel(payload.model);
            if (payload.model.defaultVatRate) {
                form.set('defaultVatRateId', payload.model.defaultVatRate.getId());
            }
            if (payload.model.productUnit) {
                form.set('productUnitId', payload.model.productUnit.getId());
            }
            if (payload.model.productCategory) {
                form.set('productCategoryId', payload.model.productCategory.getId());
            }
            if (payload.model.productImage) {
                form.set('productImageId', payload.model.productImage.getId());
            }

            if (payload.model.productType == 'composed') {
                form.set('isComposedWork', payload.model.productType == 'composed');
                if (payload.model.composedWorkLines?.items.length > 0) {
                    const promises = payload.model.composedWorkLines?.items.map(async (item) => {
                        const result = await search({
                            model: 'composed_work_lines',
                            query: item.title,
                        });

                        return result.hits[0];
                    });
                    const results = await Promise.all(promises);
                    selectedComposedWorkLines.value = results;

                    // selectedComposedWorkLines.value = results.map((item) => ({
                    //     ...item,
                    //     uuid: uuidV4() as string,
                    // }));
                    form.set('composedWorkLines', selectedComposedWorkLines.value);
                }
            }
        }
        loading.value = false;
    };

    const setProductSchema = () => {
        const schema = ProductSchema.make({
            attributes: form.dataExcept(['productUnitId', 'productCategoryId', 'defaultVatRateId', 'productImageId', 'composedWorkLines', 'isComposedWork']),
        });
        schema.addToOneRelationship('productUnit', ModelType.PRODUCT_UNITS, form.get('productUnitId') || null);
        schema.addToOneRelationship('productCategory', ModelType.PRODUCT_CATEGORIES, form.get('productCategoryId') || null);
        schema.addToOneRelationship('defaultVatRate', ModelType.VAT_RATES, form.get('defaultVatRateId') || null);
        schema.addToOneRelationship('productImage', ModelType.PRODUCT_IMAGES, form.get('productImageId') || null);
        schema.attributes.defaultQuantity = parseFloat(schema.attributes.defaultQuantity);

        return schema;
    };

    const setProductComposedWorkSchema = () => {
        const schema = ProductSchema.make({
            attributes: form.dataExcept(['productUnitId', 'productCategoryId', 'defaultVatRateId', 'productImageId', 'margin', 'isComposedWork', 'composedWorkLines']),
        });
        schema.addToOneRelationship('productUnit', ModelType.PRODUCT_UNITS, form.get('productUnitId') || null);
        schema.addToOneRelationship('defaultVatRate', ModelType.VAT_RATES, form.get('defaultVatRateId') || null);
        schema.addToOneRelationship('productImage', ModelType.PRODUCT_IMAGES, form.get('productImageId') || null);
        schema.attributes.productType = 'composed';
        schema.attributes.defaultQuantity = parseFloat(schema.attributes.defaultQuantity);

        addComposedWorkLinesOnSchema(schema);
        return schema;
    };

    const addComposedWorkLinesOnSchema = async (productSchema: ProductSchema) => {
        productSchema.attributes.content = [];
        const promises = selectedComposedWorkLines.value.map((composedWorkLine: ComposedWorkLinesIndexSchema) => saveComposedWorkLineInLibrary(composedWorkLine, productSchema));
        await Promise.all(promises);
    };

    const onValidationError = (response: ModelEndpointResponse<Product> | ModelEndpointResponse<ComposedWorkLine>) => {
        if (!response.validationErrors) {
            useToasteoError();
            return true;
        }
    };

    const saveComposedWorkLineInLibrary = (composedWorkLine: ComposedWorkLinesIndexSchema, productSchema: ProductSchema) => {
        const schema = ComposedWorkLineSchema.make({
            attributes: {
                title: composedWorkLine.title,
                quantity: composedWorkLine.default_quantity ?? composedWorkLine.quantity,
                purchasePrice: composedWorkLine.purchase_price,
                price: composedWorkLine.price,
            },
        });
        if (composedWorkLine.product_unit) {
            schema.addToOneRelationship('productUnit', ModelType.PRODUCT_UNITS, form.get('productUnitId') || null);
        }
        productSchema.attributes.content.push(schema);
        // DO WE NEED IT ?
        // schema.addToOneRelationship('parent', ModelType.PRODUCTS, product.getId());
    };

    const productEndpoint = EndpointFactory.make(ModelType.PRODUCTS);

    const response = ref<ModelEndpointResponse<Product>>();
    const submit = async () => {
        /** Creating a schema to store a Product or A Product/ComposedWork */
        const schema = form.get('isComposedWork') ? setProductComposedWorkSchema() : setProductSchema();
        if (model.value) {
            schema.id = model.value?.getId();
        }

        response.value = await form.loadUntil(productEndpoint.storeOrUpdate(schema));

        if (response.value.error) return onValidationError(response.value);

        if (response.value.data) {
            useToasteoSuccess();
            useEvent('inventory:product:updated', response.value.data);
            close();
        }
    };

    const close = () => {
        form.reset();
        shouldHide.value = false;
        selectedComposedWorkLines.value = [];
        useEvent(`${modalName}:close`);
    };
</script>

<template>
    <ModelFormModal
        :model="Product"
        :scrollable="true"
        @before-close="form.reset()"
    >
        <ModalLoader v-if="loading"></ModalLoader>
        <template v-else>
            <div :class="$theme('modal.title.container')">
                <h2 :class="$theme('modal.title.text')">
                    {{ model ? $t('inventory.product.edit_form.title') : $t('inventory.product.create_form.title') }}
                </h2>
            </div>
            <form @submit.prevent="submit">
                <div>
                    <div :class="$theme('modal.padding')">
                        <FormCheckbox
                            v-if="!shouldHide"
                            :form="form"
                            input-name="isComposedWork"
                        />
                        <div class="flex gap-4 mb-6">
                            <div class="w-1/3">
                                <InventoryProductImageSelect
                                    :selected="model?.productImage"
                                    @selected="(productImage) => form.set('productImageId', productImage?.getId() || null)"
                                />
                            </div>
                            <div class="w-full">
                                <FormInput
                                    :form="form"
                                    input-name="reference"
                                    autofocus
                                />
                                <FormInput
                                    :form="form"
                                    input-name="title"
                                />
                            </div>
                        </div>
                        <FormEditor
                            :form="form"
                            without-variables
                            input-name="description"
                        />
                        <FormThreeFields v-if="!form.get('isComposedWork')">
                            <template #field-1>
                                <FormPrice
                                    :form="form"
                                    input-name="purchasePrice"
                                />
                            </template>
                            <template #field-2>
                                <FormPercentage
                                    :form="form"
                                    input-name="margin"
                                    :min="-99999999"
                                    :max="999999999"
                                    :step="0.01"
                                    disabled
                                />
                            </template>
                            <template #field-3>
                                <FormPrice
                                    :form="form"
                                    input-name="price"
                                />
                            </template>
                        </FormThreeFields>
                        <FormThreeFields>
                            <template #field-1>
                                <FormInput
                                    input-type="number"
                                    :form="form"
                                    input-name="defaultQuantity"
                                    :min="0"
                                    :step="0.01"
                                />
                            </template>
                            <template #field-2>
                                <FormSelect
                                    :form="form"
                                    input-name="productUnitId"
                                    with-null-option
                                    :label="$t('form.labels.productUnit')"
                                >
                                    <option
                                        v-for="productUnit in productUnits?.get()"
                                        :key="productUnit.getId()"
                                        :value="productUnit.getId()"
                                    >
                                        {{ productUnit.title }}
                                    </option>
                                </FormSelect>
                            </template>
                            <template #field-3>
                                <FormSelect
                                    :form="form"
                                    input-name="defaultVatRateId"
                                    with-null-option
                                    :label="$t('form.labels.vatRate')"
                                >
                                    <option
                                        v-for="vatRate in vatRates?.get()"
                                        :key="vatRate.getId()"
                                        :value="vatRate.getId()"
                                    >
                                        {{ vatRate.title }}
                                    </option>
                                </FormSelect>
                            </template>
                        </FormThreeFields>
                        <FormSelect
                            v-if="!form.get('isComposedWork')"
                            :form="form"
                            input-name="productCategoryId"
                            with-null-option
                            :label="$t('form.labels.productCategory')"
                        >
                            <option
                                v-for="productCategory in productCategories?.get()"
                                :key="productCategory.getId()"
                                :value="productCategory.getId()"
                            >
                                {{ productCategory.title }}
                            </option>
                        </FormSelect>

                        <!-- // DRAG DROP -->
                        <div v-if="show || form.get('isComposedWork')">
                            <div class="productsContainer">
                                <div
                                    v-for="(composedWorkItem, index) in selectedComposedWorkLines"
                                    :id="`item-${composedWorkItem.uuid}`"
                                    :key="composedWorkItem.uuid"
                                    class="transition-all relative item"
                                    :class="{ placeholder: index === placeholderIndex }"
                                    draggable="true"
                                    @dragstart="startDrag($event, composedWorkItem, index)"
                                    @dragover="onDragOver($event, index)"
                                    @dragleave="onDragLeave($event, index)"
                                    @drop="onDrop($event, index)"
                                >
                                    <InventoryProductComposedWorkSearchSearchInput
                                        :item="index"
                                        :product="composedWorkItem"
                                        :list="selectedComposedWorkLines"
                                        :form="form"
                                        class="item"
                                        @select-product="(value) => selectComposedWorkLine(value, composedWorkItem)"
                                        @remove-product="() => removeComposedWorkLine(composedWorkItem)"
                                    />
                                </div>
                            </div>
                        </div>
                        <LoadingButton
                            v-if="form.get('isComposedWork')"
                            :class-name="$theme('button.style.default_sm2')"
                            @clicked="showAndAddComposedWorkLinetSelect()"
                            >{{ $t('inventory.product_section.add.composed_work_line_element') }}
                        </LoadingButton>
                    </div>
                </div>
                <div :class="$theme('modal.footer.container')">
                    <div :class="$theme('modal.footer.button_container')">
                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :class-name="$theme('button.style.cancel')"
                            prevent-default
                            @clicked="close()"
                            >{{ $t('actions.cancel') }}</LoadingButton
                        >

                        <LoadingButton
                            :extra-class-name="$theme('modal.footer.button')"
                            :loading="form.isLoading"
                            type="submit"
                            >{{ $t('actions.save') }}</LoadingButton
                        >
                    </div>
                </div>
            </form>
        </template>
    </ModelFormModal>
</template>
